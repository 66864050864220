import loader from "../../assets/loader3.svg";

const styles = {
  svgLoader: {
    animation: "fade 2s ease-in-out infinite",
    margin: "auto",
    height: "10vh",
    width: "10vh",
  },
  divLoader: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function DynamicLoader() {
  return (
    <div style={styles.divLoader}>
      <img width={100} style={styles.svgLoader} src={loader} alt="" />
    </div>
  );
}

export default DynamicLoader;
